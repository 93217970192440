import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes, } from 'react-router-dom'
import { RoutingTable } from './routing-table'

function App () {
  return (
    <BrowserRouter>
      <Routes>
        {
          // chrome 50 以前的瀏覽器不支援 Object.entries，使用 Object.keys 來代替
          Object.keys(RoutingTable).map((path) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const redirectUrl = RoutingTable[path]
            return <Route key={path} path={path} element={<RedirectPage url={redirectUrl}/>}/>
          })
        }
      </Routes>
    </BrowserRouter>
  )
}

const RedirectPage = ({
  url
}: {
  url: string;
}) => {
  useEffect(() => {
    window.location.replace(url)
  }, [])
  return <div></div>
}

export default App

export const RoutingTable = Object.freeze({
  'store-manager': 'https://drive.google.com/file/d/1ugMyEgFkJIM5tLpKmlRUwDtCWzi7CYZo/view?usp=share_link', // 特約店正式版 1.1.3 (33).apk
  'store-manager-alpha': 'https://drive.google.com/file/d/13A6R5gu66dHELGr-FwIhfZ8Nmhx-KxwR/view?usp=share_link', // 特約店測試版 1.1.3 (33).apk
  'parking-lot-manager': 'https://drive.google.com/file/d/1VPJV3Le4fIpdXA19H7A_wU61tJQUOEjJ/view?usp=drive_link', // 車總管正式版 1.2.4 (29).apk
  'parking-lot-manager-alpha': 'https://drive.google.com/file/d/1bZac2cQsBOaC5WZUSCzC5mnr_2MKCY7i/view?usp=drive_link', // 車總管測試版 1.2.4 (29).apk
  'gas-station-manager': 'https://drive.google.com/file/d/1H9eVDt3O_daUMV7U0XHIr_W-zjPJVG3P/view?usp=drive_link', // 油總管正式版 3.9.1 (189).apk
  'gas-station-manager-alpha': 'https://drive.google.com/file/d/1wl-z5CWTCvv1GYpSHo1sw9iHB3JWL2oV/view?usp=drive_link', // 油總管測試版 3.9.1 (189).apk
  'lab-devices':'https://www.notion.so/autopass/c2c3f4ef33b4444686c5311ec9649636', // 公司內部測試裝置列表 Notion 文件
  'api-docs': 'https://storage.googleapis.com/pklotcrop-swagger-doc/79455a04b9bb8e812379726aea734db9/index.html', // 公司內部 api 
  '/': 'https://www.autopass.xyz', // fallback url
})